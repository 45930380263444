
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class PageNotFound extends Vue {
  public isAnimatingBox = false;
  public isAnimatingText = false;
  public animatedElement = document.getElementById('animatedElement');

  beforeCreate() {
    document.title = '404 - Seite nicht gefunden';
  }

  mounted() {
    setTimeout(() => {
      this.isAnimatingBox = !this.isAnimatingBox;
      this.isAnimatingText = !this.isAnimatingText;
    }, 1);
  }
}
